var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"flex flex-col"},[_c('div',{staticClass:"-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8"},[_c('div',{staticClass:"py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8"},[_c('div',{staticClass:"overflow-hidden border border-gray-200 sm:rounded-lg"},[_c('table',{staticClass:"min-w-full divide-y divide-gray-200"},[_c('thead',{staticClass:"bg-gray-50"},[_c('tr',_vm._l((_vm.head),function(label,labelIndex){return _c('th',{key:labelIndex,staticClass:"px-6 py-3 text-left text-sm font-medium text-gray-500 capitalize tracking-wider",attrs:{"scope":"col"}},[_vm._v(" "+_vm._s(label.label)+" ")])}),0)]),_c('tbody',{staticClass:"bg-white divide-y divide-gray-200"},_vm._l((_vm.chunks[_vm.page - 1]),function(element,elementIndex){return _c('tr',{key:elementIndex},_vm._l((_vm.head),function(label,labelIndex){return _c('td',{key:labelIndex,staticClass:"px-6 py-4 whitespace-nowrap"},[(
                    label.key in element &&
                      ((Array.isArray(element[label.key]) &&
                        element[label.key].length !== 0 &&
                        !element[label.key][0].action) ||
                        (!Array.isArray(element[label.key]) &&
                          !element[label.key].action))
                  )?_c('div',{staticClass:"text-sm w-full text-left font-medium text-gray-900"},[(!Array.isArray(element[label.key]))?_c('div',[_c('span',{class:element[label.key].class},[_vm._v(_vm._s(element[label.key].label))])]):_c('div',{class:Array.isArray(element[label.key]) &&
                        'flex flex-col justify-center items-start'},_vm._l((element[label.key]),function(vertical,verticalIndex){return _c('span',{key:verticalIndex,class:vertical.class},[_vm._v(_vm._s(vertical.label))])}),0)]):_vm._e(),(
                    label.key in element &&
                      ((Array.isArray(element[label.key]) &&
                        element[label.key].length !== 0 &&
                        element[label.key][0].action) ||
                        (!Array.isArray(element[label.key]) &&
                          element[label.key].action))
                  )?_c('div',{staticClass:"text-sm w-full text-left font-medium text-gray-900"},[(!Array.isArray(element[label.key]))?_c('div',[_c('button',{class:element[label.key].class,on:{"click":function($event){element[label.key].action}}},[_vm._v(" "+_vm._s(element[label.key].label)+" ")])]):_c('div',{class:Array.isArray(element[label.key]) &&
                        'flex flex-col justify-center items-start'},_vm._l((element[label.key]),function(vertical,verticalIndex){return _c('button',{key:verticalIndex,class:vertical.class,on:{"click":vertical.action}},[_vm._v(" "+_vm._s(vertical.label)+" ")])}),0)]):_vm._e()])}),0)}),0)]),_c('div',{staticClass:"px-6 py-3 w-full bg-white border-t border-gray-200 flex flex-row justify-between items-center"},[_c('div',[_vm._l((_vm.pagination[0]),function(p){return _c('button',{key:p,staticClass:"rounded-md text-sm mr-2 px-3 py-1",class:p === _vm.page
                  ? 'bg-gray-900 text-white'
                  : 'bg-gray-100 hover:bg-gray-200',on:{"click":function () {
                  _vm.page = p;
                }}},[_vm._v(" "+_vm._s(p)+" ")])}),(_vm.pagination[0].length !== 0)?_c('span',{staticClass:"mr-6 ml-4"},[_vm._v("...")]):_vm._e(),_vm._l((_vm.pagination[1]),function(p){return _c('button',{key:p,staticClass:"rounded-md text-sm mr-2 px-3 py-1",class:p === _vm.page
                  ? 'bg-gray-900 text-white'
                  : 'bg-gray-100 hover:bg-gray-200',on:{"click":function () {
                  _vm.page = p;
                }}},[_vm._v(" "+_vm._s(p)+" ")])}),(_vm.pagination[2].length !== 0)?_c('span',{staticClass:"mr-6 ml-4"},[_vm._v("...")]):_vm._e(),_vm._l((_vm.pagination[2]),function(p){return _c('button',{key:p,staticClass:"rounded-md text-sm mr-2 px-3 py-1",class:p === _vm.page
                  ? 'bg-gray-900 text-white'
                  : 'bg-gray-100 hover:bg-gray-200',on:{"click":function () {
                  _vm.page = p;
                }}},[_vm._v(" "+_vm._s(p)+" ")])})],2),_c('div',{staticClass:"text-sm text-gray-700"},[_vm._v("total "+_vm._s(_vm.body.length))])])])])])])}
var staticRenderFns = []

export { render, staticRenderFns }