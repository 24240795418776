import authAxios from '@/utils/authAxios';

export default {
  getReservation: id => {
    return new Promise((resolve, reject) => {
      authAxios
        .get(
          process.env.VUE_APP_BACKEND_URL + `/reservation/get-reservation/${id}`
        )
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },

  // ---------

  next: () => {
    return new Promise((resolve, reject) => {
      authAxios
        .get(process.env.VUE_APP_BACKEND_URL + '/next_reservation')
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },

  patientHistory: id => {
    return new Promise((resolve, reject) => {
      authAxios
        .get(
          process.env.VUE_APP_BACKEND_URL + `/reservation/patient-history/${id}`
        )
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
};
