<template>
  <div class="w-full flex flex-col">
    <span class="text-xl text-gray-700 font-semibold mb-4 text-left"
      >Reservas futuras</span
    >
    <table-component
      :loading="loading"
      class="mb-8"
      :head="[
        { key: 'code', label: 'Código reserva' },
        { key: 'name', label: 'Nombre paciente' },
        { key: 'date', label: 'fecha atención' },
        { key: 'value', label: 'Costo reserva' },
        { key: 'status', label: 'Estado' },
      ]"
      :body="bodyFuture"
    />
    <span class="text-xl text-gray-700 font-semibold mb-4 text-left"
      >Reservas pasadas</span
    >
    <table-component
      :loading="loading"
      class="mb-8"
      :head="[
        { key: 'code', label: 'Código reserva' },
        { key: 'name', label: 'Nombre paciente' },
        { key: 'date', label: 'fecha atención' },
        { key: 'value', label: 'Costo reserva' },
        { key: 'status', label: 'Estado' },
      ]"
      :body="bodyPast"
    />
  </div>
</template>

<script>
import ReservationService from '@/services/reservationService';

import Table from '@/components/app/Table.vue';

export default {
  name: 'Reservations',
  components: {
    'table-component': Table,
  },
  data() {
    return {
      loading: false,
      future: [],
      past: [],
    };
  },
  created() {
    this.history();
  },
  methods: {
    history() {
      this.loading = true;
      ReservationService.patientHistory(this.$route.params.user_id)
        .then(response => {
          this.future = response.data.reservations.future;
          this.past = response.data.reservations.past;
          this.loading = false;
        })
        .catch(() => {
          this.$router.replace('/home');
        });
    },
  },
  watch: {
    '$route.params.user_id'() {
      this.history();
    },
  },
  computed: {
    bodyFuture() {
      return this.future.map(e => {
        return {
          code: {
            label: e.code,
            class: 'text-green-600',
          },
          name: {
            label: e.name,
            class: 'text-gray-700',
          },
          date: {
            label: e.date,
            class: 'text-gray-700',
          },
          value: {
            label: e.value,
            class: 'text-gray-700',
          },
          status: {
            label: e.status ? 'finalizada' : 'sin finalizar',
            class: 'text-gray-700',
          },
        };
      });
    },
    bodyPast() {
      return this.past.map(e => {
        return {
          code: {
            label: e.code,
            class: 'text-green-600',
          },
          name: {
            label: e.name,
            class: 'text-gray-700',
          },
          date: {
            label: e.date,
            class: 'text-gray-700',
          },
          value: {
            label: e.value,
            class: 'text-gray-700',
          },
          status: {
            label: e.status ? 'finalizada' : 'sin finalizar',
            class: 'text-gray-700',
          },
        };
      });
    },
  },
};
</script>
