<template>
  <div class="flex flex-col">
    <div class="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
      <div class="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
        <div class="overflow-hidden border border-gray-200 sm:rounded-lg">
          <table class="min-w-full divide-y divide-gray-200">
            <thead class="bg-gray-50">
              <tr>
                <th
                  v-for="(label, labelIndex) in head"
                  :key="labelIndex"
                  scope="col"
                  class="px-6 py-3 text-left text-sm font-medium text-gray-500 capitalize tracking-wider"
                >
                  {{ label.label }}
                </th>
              </tr>
            </thead>
            <tbody class="bg-white divide-y divide-gray-200">
              <tr
                v-for="(element, elementIndex) in chunks[page - 1]"
                :key="elementIndex"
              >
                <td
                  v-for="(label, labelIndex) in head"
                  :key="labelIndex"
                  class="px-6 py-4 whitespace-nowrap"
                >
                  <div
                    v-if="
                      label.key in element &&
                        ((Array.isArray(element[label.key]) &&
                          element[label.key].length !== 0 &&
                          !element[label.key][0].action) ||
                          (!Array.isArray(element[label.key]) &&
                            !element[label.key].action))
                    "
                    class="text-sm w-full text-left font-medium text-gray-900"
                  >
                    <div v-if="!Array.isArray(element[label.key])">
                      <span :class="element[label.key].class">{{
                        element[label.key].label
                      }}</span>
                    </div>
                    <div
                      v-else
                      :class="
                        Array.isArray(element[label.key]) &&
                          'flex flex-col justify-center items-start'
                      "
                    >
                      <span
                        v-for="(vertical, verticalIndex) in element[label.key]"
                        :key="verticalIndex"
                        :class="vertical.class"
                        >{{ vertical.label }}</span
                      >
                    </div>
                  </div>
                  <div
                    v-if="
                      label.key in element &&
                        ((Array.isArray(element[label.key]) &&
                          element[label.key].length !== 0 &&
                          element[label.key][0].action) ||
                          (!Array.isArray(element[label.key]) &&
                            element[label.key].action))
                    "
                    class="text-sm w-full text-left font-medium text-gray-900"
                  >
                    <div v-if="!Array.isArray(element[label.key])">
                      <button
                        @click="element[label.key].action"
                        :class="element[label.key].class"
                      >
                        {{ element[label.key].label }}
                      </button>
                    </div>
                    <div
                      v-else
                      :class="
                        Array.isArray(element[label.key]) &&
                          'flex flex-col justify-center items-start'
                      "
                    >
                      <button
                        v-for="(vertical, verticalIndex) in element[label.key]"
                        :key="verticalIndex"
                        :class="vertical.class"
                        @click="vertical.action"
                      >
                        {{ vertical.label }}
                      </button>
                    </div>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
          <div
            class="px-6 py-3 w-full bg-white border-t border-gray-200 flex flex-row justify-between items-center"
          >
            <div>
              <button
                v-for="p in pagination[0]"
                :key="p"
                @click="
                  () => {
                    page = p;
                  }
                "
                class="rounded-md text-sm mr-2 px-3 py-1"
                :class="
                  p === page
                    ? 'bg-gray-900 text-white'
                    : 'bg-gray-100 hover:bg-gray-200'
                "
              >
                {{ p }}
              </button>
              <span v-if="pagination[0].length !== 0" class="mr-6 ml-4"
                >...</span
              >
              <button
                v-for="p in pagination[1]"
                :key="p"
                @click="
                  () => {
                    page = p;
                  }
                "
                class="rounded-md text-sm mr-2 px-3 py-1"
                :class="
                  p === page
                    ? 'bg-gray-900 text-white'
                    : 'bg-gray-100 hover:bg-gray-200'
                "
              >
                {{ p }}
              </button>
              <span v-if="pagination[2].length !== 0" class="mr-6 ml-4"
                >...</span
              >
              <button
                v-for="p in pagination[2]"
                :key="p"
                @click="
                  () => {
                    page = p;
                  }
                "
                class="rounded-md text-sm mr-2 px-3 py-1"
                :class="
                  p === page
                    ? 'bg-gray-900 text-white'
                    : 'bg-gray-100 hover:bg-gray-200'
                "
              >
                {{ p }}
              </button>
            </div>
            <div class="text-sm text-gray-700">total {{ body.length }}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Table',
  props: {
    head: {
      type: Array,
      default: () => [],
    },
    body: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      chunkSize: 5,
      page: 1,
    };
  },
  computed: {
    pagination() {
      const total = this.chunks.length;
      const left = 3;
      const right = 3;
      let output = [[], [], []];
      //middle section
      for (let i = this.page - left; i <= this.page + right; i++) {
        if (i > 0 && i <= total) {
          output[1].push(i);
        }
      }
      // left section
      for (let i = 1; i <= 2; i++) {
        if (!output[1].includes(i) && i <= total) {
          output[0].push(i);
        }
      }

      // right section
      for (let i = total - 1; i <= total; i++) {
        if (!output[1].includes(i) && i > 0) {
          output[2].push(i);
        }
      }

      return output;
    },
    chunks() {
      let output = [];
      for (let i = 0; i < this.body.length; i += this.chunkSize) {
        const chunk = this.body.slice(i, i + this.chunkSize);
        output.push(chunk);
      }
      return output;
    },
  },
};
</script>
